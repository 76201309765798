<template>
  <div>
    <EventDetail></EventDetail>
  </div>
</template>

<script>
import EventDetail from '../components/EventDetail.vue';

export default {
  components: {
    EventDetail
  },
}
</script>

<style scoped>

</style>