<template>
  <v-container fluid pa-0 style="max-width: 1250px;">
    <div v-for="event in events" :key="event.id">
      <div
          class="mt-8 mx-4"
      >
        <v-row>
          <v-col :class="responsiveColumsBig()">
            <v-img
                style="max-height:450px; border-radius: 5px;"
                :src="pretixServer + '/' + event.logo_imageurl"
            ></v-img>

            <h5 class="px-2 pt-4">
              <v-btn
                  icon
                  x-small
                  class="my-2 mx-4"
              >
                  <font-awesome-icon :icon="['fa', 'share']" />
              </v-btn>
              {{ $t('event.sharing_title') }}
            </h5>

            <v-row no-gutters class="px-2" style="max-width: 300px">
              <v-col @click="trackClickSharing('facebook')">
                <ShareNetwork
                    network="facebook"
                    :url="pretixServer + '/' + event.organizer_slug + '/' + event.slug"
                    title="Wer ist dabei?"
                    hashtags="kumscho,kumunity"
                >
                  <div class="text--primary py-4 text-center">
                    <font-awesome-icon :icon="['fab', 'facebook-f']" />
                  </div>
                </ShareNetwork>
              </v-col>

              <v-col @click="trackClickSharing('twitter')">
                <ShareNetwork
                    network="twitter"
                    :url="pretixServer + '/' + event.organizer_slug + '/' + event.slug"
                    title="Wer ist dabei?"
                    hashtags="kumscho,kumunity"
                >
                  <div class="text--primary py-4 text-center">
                    <font-awesome-icon :icon="['fab', 'twitter']" />
                  </div>
                </ShareNetwork>
              </v-col>

              <v-col @click="trackClickSharing('linkedin')">
                <ShareNetwork
                    network="linkedin"
                    :url="pretixServer + '/' + event.organizer_slug + '/' + event.slug"
                    title="Wer ist dabei?"
                >
                  <div class="text--primary py-4 text-center">
                    <font-awesome-icon :icon="['fab', 'linkedin']" />
                  </div>
                </ShareNetwork>
              </v-col>

              <v-col @click="trackClickSharing('email')">
                <ShareNetwork
                    network="email"
                    :url="pretixServer + '/' + event.organizer_slug + '/' + event.slug"
                    title="Bist du dabei?"
                >
                  <div class="text--primary py-4 text-center">
                    <font-awesome-icon :icon="['fa', 'envelope']" />
                  </div>
                </ShareNetwork>
              </v-col>

              <v-col @click="trackClickSharing('whatsapp')">
                <ShareNetwork
                    network="whatsapp"
                    :url="pretixServer + '/' + event.organizer_slug + '/' + event.slug"
                    title="Bist du dabei?"
                >
                  <div class="text--primary py-4 text-center">
                    <font-awesome-icon :icon="['fab', 'whatsapp']" />
                  </div>
                </ShareNetwork>
              </v-col>

            </v-row>

            <div class="mx-4">
              <h3 class="mt-4 mb-4">{{ getLangName(event.name) }}</h3>
              <div class="body-2" v-html="getLangDescription(event.event_description)"></div>
            </div>
          </v-col>

          <v-col :class="responsiveColumsSmall()" style="min-width: 300px" class="pt-8">

            <div class="mb-6">
              <div class="mx-4 body-1 font-weight-bold">{{ $t('event.location') }}</div>
              <div class="body-2 mx-4" v-html="getLangLocationHtml(event.location)"></div>
              <div class="body-2 mx-4">
                <a
                  @click="trackClickLocation()"
                  class="blue--text" target="_blank"
                  :href="'https://www.google.com/maps/search/?api=1&query=' + event.geo_lat + ',' + event.geo_lon"
                >
                  {{ $t('event.show_map') }}
                </a>
              </div>
            </div>

            <div class="mb-6" v-if="!event.has_subevents">
              <div class="mx-4 body-1 font-weight-bold">{{ $t('event.date_time') }}</div>
              <div class="body-2 mx-4">{{ getFormatedDate(event.date_from) }}</div>
              <div class="body-2 mx-4">
                <a class="blue--text" :href="pretixServer + '/' + event.organizer_slug + '/' + event.slug + '/ical'" target="_blank"
                   @click="trackClickCal()">
                  {{ $t('event.add_calendar') }}
                </a>
              </div>
            </div>

            <div class="mx-4">
              <p class="body-2" v-if="event.funding_type && event.has_subevents">{{ $t('event.funding_explanation') }}</p>
              <v-expansion-panels class="mb-6" v-model="panel">
                <v-expansion-panel
                    multiple
                    v-for="subevent in subevents"
                    :key="subevent.id"
                >
                  <v-expansion-panel-header>
                    {{getFormatedDate(subevent.date_from)}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row v-if="event.funding_type"
                        class="mx-1 mb-2"
                        align="center"
                    >
                      <div style="width: 100%">
                        <div class="progress">
                          <div class="progress-bar" v-bind:style="{ width: setProgressWidthSizeSubEvent(event.min_amount, subevent.total_amount, event.funding_type, subevent.ticket_count) + '%'}"></div>
                        </div>
                        <div class="float-left body-2">
                          <b>{{getFundPercentageSubEvent(event.min_amount, subevent.total_amount, event.funding_type, subevent.ticket_count)}}%
                            {{ $t('event.reached') }}</b></div>
                        <div class="float-right body-2" v-if="!event.over"><b>{{ getDaysToGo(subevent.date_from) }} {{ $t('event.togo') }}</b></div>
                      </div>
                    </v-row>
                    <v-row v-if="event.funding_type"
                        class="mx-1 mb-4"
                    >
                      <div class="body-2 float-left">{{ subevent.ticket_count }} {{ $t('event.participants') }}</div>
                    </v-row>

                    <v-row class="px-3 mb-2">
                      <a
                          :href="pretixServer + '/' + event.organizer_slug + '/' + event.slug + '/' + subevent.id"
                          target="_blank"
                          style="text-decoration: none; width: 100%;"
                          @click="trackClick()"
                      >
                        <v-btn
                            elevation="0"
                            large
                            color="secondary"
                            style="color: white"
                        >{{ $t('event.buy_tickets') }}
                        </v-btn>
                      </a>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>

            <div class="funding-section-big">
              <h5 v-if="event.funding_type && !event.has_subevents" class="ml-2">
                <v-btn
                    icon
                    x-small
                    class="my-2 mx-1 text--primary mt-1"
                >
                    <font-awesome-icon :icon="['fa', 'info']" />
                </v-btn>
                {{ $t('event.funding_title') }}
              </h5>

              <v-row no-gutters class="px-4">
                <div class="caption" v-if="event.funding_type && !event.has_subevents">{{ $t('event.funding_explanation') }}</div>
              </v-row>
              <v-row
                  class="mr-4 ml-4 mb-2"
                  align="center"
                  v-if="event.funding_type && !event.has_subevents"
              >
                <div style="width: 100%">
                  <div class="progress">
                    <div class="progress-bar" v-bind:style="{ width: progressWidthSize + '%'}"></div>
                  </div>

                  <div class="float-left body-2">
                    <b>{{
                        getFundPercentage(event.min_amount, event.total_amount, event.funding_type, event.ticket_count)
                      }}%
                      {{ $t('event.reached') }}</b></div>
                  <div class="float-right body-2" v-if="!event.over"><b>{{ getDaysToGo(event.date_from) }} {{ $t('event.togo') }}</b></div>
                </div>
              </v-row>
              <v-row
                  class="mx-4 mb-8"
                  v-if="event.funding_type && !event.has_subevents"
              >
                <div class="body-2 float-left">{{ event.ticket_count }} {{ $t('event.participants') }}</div>
              </v-row>

              <v-row class="mx-4 mb-4">
                <a
                    :href="pretixServer + '/' + event.organizer_slug + '/' + event.slug"
                    target="_blank"
                    style="text-decoration: none; width: 100%;"
                    @click="trackClick()"
                >
                  <v-btn v-if="event.has_subevents"
                      elevation="0"
                      x-large
                      color="secondary"
                      block
                         outlined
                      style="color: white"
                  >{{ $t('event.buy_tickets_overview') }}
                  </v-btn>
                  <v-btn v-if="!event.has_subevents"
                      elevation="0"
                      x-large
                      color="secondary"
                      block
                      style="color: white"
                  >{{ $t('event.buy_tickets') }}
                  </v-btn>
                </a>
                <a href="http://eepurl.com/hoZPgb" class="mt-4 text-center blue--text" target="_blank">{{ $t('newsletter.subscribe') }}</a>
              </v-row>
            </div>


          </v-col>
        </v-row>

        <v-btn
            outlined
            class="mx-4 mb-8 mt-4"
            to="/"
        >
          {{ $t('back') }}
        </v-btn>
      </div>

      <div class="funding-section-small pt-2 px-4" v-if="event.funding_type && !event.has_subevents">
        <h5 v-if="event.funding_type" class="ml-2">
          <v-btn
              icon
              x-small
              class="my-2 mx-1 text--primary mt-1"
          >
            <font-awesome-icon :icon="['fa', 'info']" />
          </v-btn>
          {{ $t('event.funding_title') }}
        </h5>

        <v-row class="mx-4 mt-1">
          <div class="caption">{{ $t('event.funding_explanation') }}</div>
        </v-row>
        <v-row
            class="mr-4 ml-4 mb-2"
            align="center"
        >
          <div style="width: 100%">
            <div class="progress">
              <div class="progress-bar" v-bind:style="{ width: progressWidthSize + '%'}"></div>
            </div>

            <div class="float-left body-2">
              <b>{{
                  getFundPercentage(event.min_amount, event.total_amount, event.funding_type, event.ticket_count)
                }}%
                {{ $t('event.reached') }}</b></div>
            <div class="float-right body-2" v-if="!event.over"><b>{{ getDaysToGo(event.date_from) }} {{ $t('event.togo') }}</b></div>
          </div>
        </v-row>
        <v-row
            class="mx-4 mb-4"
        >
          <div class="body-2 float-left">{{ event.ticket_count }} {{ $t('event.participants') }}</div>
        </v-row>

        <v-row class="mx-4 mb-4">
          <a
              :href="pretixServer + '/' + event.organizer_slug + '/' + event.slug"
              target="_blank"
              style="text-decoration: none; width: 100%;"
              @click="trackClick()"
          >
            <v-btn
                elevation="0"
                x-large
                color="secondary"
                block
                style="color: white"
                class="mb-4"
            >{{ $t('event.buy_tickets') }}
            </v-btn>
          </a>
        </v-row>
      </div>

      <div class="no-funding-section-small" v-if="!event.funding_type || event.has_subevents" style=" z-index: 100;">
        <v-row class="px-11 pb-12 mb-12">
          <a
              :href="pretixServer + '/' + event.organizer_slug + '/' + event.slug"
              target="_blank"
              style="text-decoration: none; width: 100%;"
              @click="trackClick()"
          >
            <v-btn v-if="event.has_subevents"
                elevation="0"
                x-large
                color="secondary"
                block
                   outlined
                style="color: white;"
            >{{ $t('event.buy_tickets_overview') }}
            </v-btn>
            <v-btn v-if="!event.has_subevents"
                elevation="0"
                x-large
                color="secondary"
                block
                style="color: white;"
            >{{ $t('event.buy_tickets') }}
            </v-btn>
          </a>
        </v-row>
      </div>
    </div>
  </v-container>

</template>

<script>
import * as api from "@/api/apiserver";
import * as config from '@/api/config'
import * as helpers from '@/utils/helpers'

export default {
  name: 'EventDetail',
  metaInfo() {
    if (this.events.length > 0){
      return {
        title: this.getLangName(this.events[0].name),
        meta: [
          { name: 'description', content:  this.events[0].list_text},
          { property: 'og:title', content: this.getLangName(this.events[0].name)}
        ]
      }
    }
  },
  data: () => (
      {
        panel: 0,
        show: false,
        events: [],
        subevents: [],
        value: 45,
        max: 100,
        progressWidthSize: 60,
        minAmount: 0,
        totalAmount: 0
      }
  ),
  methods: {
    trackClick() {
      window.analytics.track('Clicked Event Buy Ticket')
    },

    trackClickSharing(type){
      window.analytics.track('Clicked Event Share', {type: type})
    },

    trackClickCal(){
      window.analytics.track('Clicked Event Calendar Download')
    },

    trackClickLocation(){
      window.analytics.track('Clicked Event Open Location')
    },

    async getEvent(slug) {
      this.events = await api.getEventsBySlug(slug);
      if (this.events.length == 0){
        this.events = await api.getPastEventsBySlug(slug);
        this.events[0].over = true;
      }

      if (this.events[0].has_subevents){
        this.subevents = await api.getSubEvents(this.events[0].id);
        //remove events that passed
        let index = this.subevents.length - 1;
        while (index >= 0){
          let date = new Date(this.subevents[index].date_from)
          let now = new Date()
          if (date < now) {
            this.subevents.splice(index, 1);
          }
          index -= 1;
        }
        this.subevents.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.date_from) - new Date(b.date_from);
        });
      }
    },

    responsiveColumsBig() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return "col-12"
        case 'sm':
          return "col-12"
        case 'md':
          return "col-8"
        case 'lg':
          return "col-8"
        case 'xl':
          return "col-8"
      }
    },

    responsiveColumsSmall() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return "col-12"
        case 'sm':
          return "col-12"
        case 'md':
          return "col-4"
        case 'lg':
          return "col-4"
        case 'xl':
          return "col-4"
      }
    },

    getLangName(item) {
      return helpers.getLangName(item)
    },

    getLangDescription(item) {
      return helpers.getLangText(item).replace(/(?:\r\n|\r|\n)/g, '<br />');
    },

    getLangLocation(item) {
      return helpers.getLangLocation(item);
    },

    getLangLocationHtml(item) {
      return helpers.getLangLocation(item).replace(/(?:\r\n|\r|\n)/g, '<br />');
    },

    getFormatedDate(value) {
      return helpers.getFormatedDate(value)
    },

    getDaysToGo(value) {
      return helpers.getDaysToGo(value)
    },

    getFundPercentage(minAmount, totalAmount, funding_type, ticket_count) {
      if (!this.events[0].has_subevent){
        this.minAmount = minAmount;

        if (funding_type === "crowdfunding") {
          this.totalAmount = totalAmount;
        } else if (funding_type === "ticketcount") {
          this.totalAmount = ticket_count;
        }

        this.setProgressWidthSize(this.minAmount, this.totalAmount);

        if (totalAmount > 0) {
          return Math.round(this.totalAmount / this.minAmount * 100 * 100) / 100;
        }
        return 0;
      }
    },

    getFundPercentageSubEvent(minAmount, totalAmount, funding_type, ticket_count) {
      let total = totalAmount;
      let min = minAmount;

        if (funding_type === "ticketcount") {
          total = ticket_count;
        }

        if (totalAmount > 0) {
          return Math.round(total / min * 100 * 100) / 100;
        }
        return 0;
    },

    setProgressWidthSizeSubEvent(minAmount, totalAmount, funding_type, ticket_count) {
      if (funding_type === "ticketcount") {
        totalAmount = ticket_count;
      }

      let percentage = totalAmount / minAmount * 100;

      if (percentage > 100) {
        return 100;
      } else if (percentage <= 0) {
        return 0;
      } else {
        return percentage;
      }
    },

    setProgressWidthSize(minAmount, totalAmount) {
      let percentage = totalAmount / minAmount * 100;
      if (percentage > 100) {
        this.progressWidthSize = 100;
      } else if (percentage <= 0) {
        this.progressWidthSize = 0;
      } else {
        this.progressWidthSize = percentage;
      }
    },

  },
  mounted() {
    this.getEvent(this.$route.params.slug);
  },
  computed: {
    kumschoServer() {
      return config.KUMSCHO_SERVER
    },
    pretixServer() {
      return config.KUMSCHO_PRETIX_SERVER
    },
    cssVars() {
      return {
        '--width': this.getFundPercentage(100, 80) + '%'
      }
    }
  }
}
</script>

<style>
.progress {
  height: 10px;
  width: 100%;
  background-color: #eee;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.progress-bar {
  height: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 4px;
  float: left;
  /*background: linear-gradient(to right, #f2709c 0%, #ff9472 100%);*/
  background: #00C853;
}

.fa {
  padding: 12px 0 12px 0;
  border-radius: 5px;
  font-size: 20px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

.funding-section-small {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #f9f9f9;
  border-top: 3px solid #EEEEEE;
}

.no-funding-section-small {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: white;
}

@media (min-width: 961px) {
  .funding-section-small {
    display: none;
  }
}

@media (min-width: 961px) {
  .no-funding-section-small {
    display: none;
  }
}

@media (max-width: 960px) {
  .funding-section-big {
    display: none;
  }
}
</style>